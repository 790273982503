import {
  GoogleAuthProvider,
} from "firebase/auth";
import {
  Button,
  Stack,
  Typography,
} from "@mui/material";
import { Google } from "@mui/icons-material";
import { auth } from "../../firebase/firebase.js";
import PermContact from "src/assets/svg/permcontact.js";
import { Helmet } from "react-helmet";
import { useSnackbarContext } from "src/utils/SnackbarContext.js";
import { useNavigate } from "react-router-dom";
import { getApiRoute } from "src/services";
import { getPageRoute } from "src/services";

function ConnectGoogleCalendar() {
  const {
    setSnackbarOpen,
    setSnackbarMessage,
    setSnackbarSeverity,
    showSnackBar,
  } = useSnackbarContext();

  const navigate = useNavigate();
   
  const connectGoogleCalendar = async () => {
    const clientId = process.env.REACT_APP_OAUTH2_CLIENT_ID;
      const redirectUri = process.env.REACT_APP_OAUTH2_REDIRECT_URI;
      const scope = encodeURIComponent("https://www.googleapis.com/auth/calendar");
      const responseType = "code";
      const accessType = "offline"; // Necessary to receive a refresh token
      const prompt = "consent";      // Ensure consent screen is always shown to get refresh token
  
      const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}&access_type=${accessType}&prompt=${prompt}`;
  
      // Redirect the user to Google's OAuth 2.0 consent screen
      window.location.href = authUrl;
  };

  return (
    <>
      <Helmet>
        <title>Connect Your Google Calendar</title>
        <meta name="description" content="Sign in to Stanza." />
      </Helmet>
      <Stack spacing={2.5} sx={{ width: "600px", height: "100%", margin: "0 auto", mt: 20}}>
        <Button
          variant="outlined"
          size="grown"
          startIcon={<Google />}
          onClick={connectGoogleCalendar}
          sx={{
            width: "100%",
            gap: { xs: 1, md: 0 },
          }}
        >
          Connect Google Calendar
        </Button>
      </Stack>
    </>
  );
};

export default ConnectGoogleCalendar;
