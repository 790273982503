import UAParser from "ua-parser-js";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { useTheme } from "@emotion/react";
import PermContact from "src/assets/svg/permcontact.js";
import {
  Box,
  Icon,
  Typography,
  Button,
  Divider,
  Tooltip,
  IconButton,
  Link,
} from "@mui/material";
import { ContactSupportOutlined } from "@mui/icons-material";
import RecommendedCalendars from "../blocks/RecommendedCalendars.js";
import { AuthContext } from "src/utils/AuthContext.js";
import { useMutation } from "@tanstack/react-query";
import { LoadingButton } from "../blocks/LoadingButton.js";
import { generateGCalReminder } from "src/utils/Utils.js";
import { getPageRoute } from "src/services";
import { customEvent } from "src/utils/gtag.js";

const GoogleCalendarExperience = () => {
  const navigate = useNavigate();
  const uaParser = new UAParser();
  const parserResults = uaParser.getResult();
  const [step, setStep] = useState("MainSection");

  const location = useLocation();
  const calendar = location.state ? location.state.calendar ?? null : null;
  const theme = useTheme();

  // const isAndroid = parserResults.os.name === "Android";

  const isAndroid = true;

  useEffect(() => {
    // if (!isAndroid || !calendar) {
    //   navigate(getPageRoute("home", "HOME"));
    // }
  }, [isAndroid, calendar]);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Box
        id="container"
        sx={{
          display: "flex",
          flexDirection: "column",
          margin:
            step === "MainSection" ? "15% auto 0 auto" : "50% auto 0 auto",
          maxWidth: "600px",
          position: "relative",
          p: 2,
          alignItems: step !== "subscribeOnDesktop" ? "center" : "start",
          textAlign: step !== "subscribeOnDesktop" ? "center" : "start",
        }}
      >
        {
          {
            MainSection: <MainSection setStep={setStep} calendar={calendar} />,
            oneTimeDownload: (
              <OneTimeDownload calendar={calendar} setStep={setStep} />
            ),
          }[step]
        }
      </Box>
    </div>
  );
};


const MainSection = ({ setStep, calendar }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const user = useContext(AuthContext).user;

  const handleDownloadGCalReminder = async () => {
    customEvent({
      name: "download_gcal_reminder",
      category: "GoogleCalendarExperience",
      label: "Download Google Calendar Reminder Click",
      data: {
        user_id: user._id,
        user_email: user.email,
        user_handle: user.handle ?? "no_handle",
      },
    });

    const stanzaLink = `${calendar?.stanzaLink}/${user?._id}.ics`;
    const icsContent = generateGCalReminder(calendar?.name, stanzaLink);
    const blob = new Blob([icsContent], {
      type: "text/calendar;charset=utf-8",
    });
    const url = URL.createObjectURL(blob);

    // Create a temporary link to trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.download = `Sync_${calendar.name}_GCal_Reminder.ics`;

    // Append to the document and trigger the click
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    
    return true;
  };

  const {
    mutateAsync: downloadGCalReminder,
    data: isDone,
    isLoading,
  } = useMutation(handleDownloadGCalReminder);

  useEffect(() => {
    if (isDone) {
      navigate(getPageRoute("home", "HOME"), {
        state: {
          recommendedCalendars: {
            calendar,
            displayMode: "downloadGCalReminderConfirmation",
          },
        },
      });
    }
  }, [isDone]);


  const handleOneTimeDownload = () => {
    setStep("oneTimeDownload");
  };

  return (
    <>
      <PermContact />
      <Typography variant={"h2"} sx={{ mt: 1.5 }}>
        Google Calendar App doesn't support new calendar subscriptions
      </Typography>
      <Typography variant={"subtitle2"} sx={{ mt: 1.5 }} color="text.secondary">
        You can show or hide existing calendars but you can only subscribe to
        new calendars on the web.
      </Typography>
      <Divider sx={{ my: 2.5 }}> </Divider>
      <Typography variant={"subtitle1"} color="text.secondary">
        Here are your options:
      </Typography>
      <LoadingButton
        variant="contained"
        size="grown"
        sx={{ mt: 2, width: "280px" }}
        loading={isLoading}
        onClick={(event) => {
          event.preventDefault();
          downloadGCalReminder();
        }}
      >
        SUBSCRIBE ON DESKTOP & SYNC
      </LoadingButton>
      <Typography
        variant={"subtitle2"}
        sx={{
          mt: 1.5,
          color: `${theme.palette.text.secondary} !important`,
          width: "280px",
        }}
      >
        Set a reminder with instructions on how to do this. Your calendar{" "}
        <u>will</u> update automatically.
      </Typography>
      <Box sx={{ width: "100%", maxWidth: "280px" }}>
        <Divider sx={{ mt: 3, mb: 3.5 }}>
          <Typography variant="subtitle2" color="text.secondary">
            or
          </Typography>
        </Divider>
      </Box>

      <Button
        variant="outlined"
        size="grown"
        sx={{ width: "280px" }}
        onClick={handleOneTimeDownload}
      >
        ONE-TIME DOWNLOAD
      </Button>
      <Typography
        variant={"subtitle2"}
        sx={{
          mt: 2,
          color: `${theme.palette.text.secondary} !important`,
          width: "280px",
        }}
      >
        These events <u>will not</u> update automatically. You'll need to
        resubscribe to see the latest.
      </Typography>
    </>
  );
};

const OneTimeDownload = ({ calendar, setStep }) => {
  const theme = useTheme();
  const { user } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleDownloadCal = async () => {
    customEvent({
      name: "download_one_time_calendar",
      category: "GoogleCalendarExperience",
      label: "Download One Time Calendar Click",
      data: {
        user_id: user._id,
        user_email: user.email,
        user_handle: user.handle ?? "no_handle",
      },
    });

    const link = document.createElement("a");
    const calendarApiUrl = `${calendar?.stanzaLink}/${user?.id}.ics`;
    link.href = calendarApiUrl;
    link.download = `${calendar?.name}.ics`;
    const keydownEvent = (e) => {
      if (e.key === "d") {
        const event = new MouseEvent("click", {});
        link.dispatchEvent(event);
      }
    };
    document.addEventListener("keydown", keydownEvent, { once: true });
    // Programmatically click the link to trigger the download by an event
    document.body.appendChild(link);
    const event = new KeyboardEvent("keydown", { key: "d" });
    document.dispatchEvent(event);
    // Remove the link from the document
    document.body.removeChild(link);
    return true;
  };

  const {
    mutateAsync: downloadCalendar,
    isLoading,
    data: downloadDone,
  } = useMutation(handleDownloadCal);

  useEffect(() => {
    if (downloadDone) {
      navigate(getPageRoute("home", "HOME"), {
        state: {
          recommendedCalendars: {
            calendar,
            displayMode: "oneTimeDownloadConfirmation",
          },
        },
      });
    }
  }, [downloadDone]);

  return (
    <>
      <Typography variant="h2">This is a one-time download</Typography>
      <Typography
        variant="subtitle2"
        color="text.secondary"
        sx={{ mt: 1.5, px: 1 }}
      >
        By continuing, you acknowledge that this calendar won't auto-update. To
        get the latest event changes, you'll need to resubscribe.
      </Typography>
      <LoadingButton
        variant="contained"
        size="grown"
        sx={{ mt: 4 }}
        style={{ width: "280px" }}
        onClick={downloadCalendar}
        loading={isLoading}
      >
        CONTINUE DOWNLOADING
      </LoadingButton>
      <Button
        variant="text"
        size="grown"
        sx={{ width: "280px", mt: 2, color: "text.secondary" }}
        onClick={() => setStep("MainSection")}
      >
        GO BACK
      </Button>
    </>
  );
};


export default GoogleCalendarExperience;
