import axios from "axios";
import { getApiRoute } from "src/services";
import { useMutation } from "@tanstack/react-query";

async function stripeCheckout({ type = "consumer", successUrl }) {
  return axios.post(
    getApiRoute("upgrade", "STRIPE_CHECKOUT"),
    { type, successUrl: successUrl },
    { withCredentials: true }
  );
}

export function useStripeCheckout() {
  return useMutation(stripeCheckout);
}
